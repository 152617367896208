import Vue from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import Buefy from 'buefy'
import VueSocketIO from 'vue-socket.io'
import VueCookies from 'vue-cookies'
import './vee-validate'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Clipboard from 'v-clipboard'

import VueGtag from "vue-gtag"
import VueConfetti from 'vue-confetti'
import CountryFlag from 'vue-country-flag'
import VueWaypoint from 'vue-waypoint'
import posthog from "posthog-js"

//Install lodash and add to vue prototype
import lodash from 'lodash'
Vue.prototype._ = _

import ApiService from './common/api.service'
import './common/filters'
import { loadingMixin } from "./common/mixins"
import AchievementReceived from './components/Achievement/AchievementReceived.vue'
import './common/directives'

Vue.config.productionTip = process.env.NODE_ENV === 'production'

Vue.use(VueConfetti)
Vue.use(Buefy)
Vue.use(VueCookies)

Vue.$cookies.config("7d", null, process.env.VUE_APP_COOKIE_DOMAIN)

// Initialize Google Analytics with default configuration (disabled)
Vue.use(VueGtag, {
  config: { 
    id: process.env.VUE_APP_GA_ID,
    enabled: false // Disabled by default
  },
  bootstrap: false // Don't load analytics until consent is given
})

// Initialize API Service before checking cookie consent
Vue.prototype.$http = new ApiService()

// Create a dummy PostHog instance that always returns true for feature flags
const dummyPosthog = {
  capture: () => {},
  identify: () => {},
  isFeatureEnabled: () => true,
  opt_out_capturing: () => {},
  register: () => {},
  unregister: () => {},
  getFeatureFlag: () => true,
  reloadFeatureFlags: () => Promise.resolve(),
  onFeatureFlags: (callback) => callback(),
}

// Initialize PostHog with a disabled state by default
Vue.prototype.$posthog = dummyPosthog

// Function to initialize PostHog
const initializePostHog = () => {
  return new Promise((resolve) => {
    posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
      api_host: process.env.VUE_APP_POSTHOG_HOST || "https://app.posthog.com",
      loaded: function(posthog) {
        if (process.env.NODE_ENV === 'development') {
          posthog.opt_out_capturing()
        }
        Vue.prototype.$posthog = posthog
        resolve()
      }
    })
  })
}

// Initialize analytics only if consent exists and analytics are allowed
const cookieConsent = Vue.$cookies.get('cookie_consent')

// Initialize Vue app after PostHog is ready
const initializeApp = async () => {
  if (cookieConsent && cookieConsent.analytics) {
    // Enable Google Analytics
    Vue.$gtag.enabled(true)
    Vue.$gtag.bootstrap() // Load analytics scripts

    // Wait for PostHog to initialize
    await initializePostHog()
  } else {
    // Ensure PostHog is disabled if no consent
    if (window.posthog) {
      posthog.opt_out_capturing()
    }
    
    // Add a global flag to track analytics state
    Vue.prototype.$analyticsEnabled = false
  }

  Vue.use(Clipboard)
  Vue.component('country-flag', CountryFlag)
  Vue.use(VueWaypoint)
  Vue.use(loadingMixin)
  Vue.use(
    new VueSocketIO({
      debug: false,
      connection: process.env.VUE_APP_API,
      vuex: {
        store,
        actionPrefix: "SOCKET_",
        mutationPrefix: "SOCKET_"
      },
      withCredentials: false,
      secure: true,
    })
  )

  // Only set up token-based features if we have functional consent
  const token = cookieConsent && cookieConsent.functional ? Vue.$cookies.get('token') : null

  if (token) {
    const connectToSocket = () => {
      const eventSource = new EventSource(`${process.env.VUE_APP_API}/sse/${token}`)
      eventSource.onmessage = ({ data }) => {
        const parsed = JSON.parse(data)
        if (parsed.type == 'achievement') {
          Vue.prototype.$buefy.modal.open({
            parent: this,
            component: AchievementReceived,
            trapFocus: true,
            animation: "zoom-in",
            props: {
              achievement: parsed.data
            }
          })
        }
      }
      eventSource.onerror = (err) => {
        console.log('error', err)
        setTimeout(() => {
          connectToSocket()
        }, 2000)
      }
    }

    connectToSocket()
  }

  new Vue({
    router: router,
    store: store,
    render: (h) => h(App),
    mounted() {
      AOS.init({
        duration: 600,
        once: true,
      })
    }
  }).$mount('#app')
}

// Start the application
initializeApp()
