<template>
  <div class="modal-card" style="max-width: 500px; margin: 0 auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Cookie Voorkeuren</p>
    </header>
    <section class="modal-card-body">
      <div class="content">
        <p>
          Wij gebruiken cookies om je de beste ervaring op onze website te bieden. Cookies helpen ons bij:
        </p>
        <ul>
          <li>Het onthouden van je inloggegevens</li>
          <li>Het bijhouden van je voortgang</li>
          <li>Het verbeteren van onze website</li>
          <li>Het personaliseren van je ervaring</li>
        </ul>
        <div class="cookie-options">
          <div class="cookie-option">
            <b-field>
              <b-switch v-model="essentialCookies" disabled>
                Essentiële cookies
              </b-switch>
            </b-field>
            <p class="help">Deze cookies zijn noodzakelijk voor het functioneren van de website en kunnen niet worden uitgeschakeld.</p>
          </div>
          <div class="cookie-option">
            <b-field>
              <b-switch v-model="analyticsCookies">
                Analytische cookies
              </b-switch>
            </b-field>
            <p class="help">Deze cookies helpen ons te begrijpen hoe bezoekers onze website gebruiken.</p>
          </div>
          <div class="cookie-option">
            <b-field>
              <b-switch v-model="functionalCookies">
                Functionele cookies
              </b-switch>
            </b-field>
            <p class="help">Deze cookies onthouden je voorkeuren en verbeteren je gebruikerservaring.</p>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <b-button @click="rejectAll" type="is-light">
        Alles weigeren
      </b-button>
      <b-button @click="acceptSelected" type="is-primary">
        Voorkeuren opslaan
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'CookieConsentModal',
  data() {
    return {
      essentialCookies: true,
      analyticsCookies: true,
      functionalCookies: true
    }
  },
  methods: {
    rejectAll() {
      this.analyticsCookies = false
      this.functionalCookies = false
      this.acceptSelected()
    },
    acceptSelected() {
      const preferences = {
        essential: true, // Always true
        analytics: this.analyticsCookies,
        functional: this.functionalCookies,
        consentDate: new Date().toISOString()
      }
      
      // Store the consent preferences
      this.$cookies.set('cookie_consent', preferences, '365d')
      
      // Emit the preferences to parent
      this.$emit('preferences-saved', preferences)
      
      // Close the modal
      this.$parent.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.cookie-options {
  margin-top: 1.5rem;
}

.cookie-option {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 4px;

  .help {
    margin-top: 0.5rem;
    color: #666;
  }
}

.modal-card-foot {
  justify-content: flex-end;
  gap: 1rem;
}
</style> 