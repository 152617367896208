<template>
  <div>
    <div id="app" :class="{ darkClass: darkMode }">
      <sidebar v-if="!hideSidebar && username" ref="sidebar" :collapsed="collapsed" @collapseSidebar="collapse()">
        <div slot="links">
          <router-link to="/dashboard">
            <b-icon icon="home-outline" class="mr-5" custom-size="mdi-36px" />
            <span class="link-text">Dashboard</span>
          </router-link>
          <router-link v-if="questionAnswerFeature" to="/chat">
            <b-icon icon="robot-happy-outline" class="mr-5" custom-size="mdi-36px" />
            <span class="link-text">StudyBuddy</span>
          </router-link>
          <template v-if="!isStudyBuddyRoute">
            <router-link to="/planning" @click.native="redirectPlanning()">
              <b-icon icon="calendar-outline" class="mr-5" custom-size="mdi-36px" />
              <span class="link-text">Planning</span>
            </router-link>
            <router-link to="/results">
              <b-icon icon="chart-timeline-variant" class="mr-5" custom-size="mdi-36px" />
              <span class="link-text">Resultaten</span>
            </router-link>
            <router-link to="/books">
              <b-icon icon="book-outline" class="mr-5" custom-size="mdi-36px" />
              <span class="link-text">Boeken</span>
            </router-link>
            <router-link to="/folders">
              <b-icon icon="folder-outline" class="mr-5" custom-size="mdi-36px" />
              <span class="link-text">Mappen</span>
            </router-link>
            <router-link to="/groups">
              <b-icon icon="account-group-outline" class="mr-5" custom-size="mdi-36px" />
              <span class="link-text">Groepen</span>
            </router-link>
            <router-link to="/search">
              <b-icon icon="magnify" class="mr-5" custom-size="mdi-36px" />
              <span class="link-text">Zoeken</span>
            </router-link>
          </template>
          <router-link to="/contact">
            <b-icon icon="email-outline" class="mr-5" custom-size="mdi-36px" />
            <span class="link-text">Contact</span>
          </router-link>
        </div>
      </sidebar>
      <transition name="fade" mode="out-in">
        <div id="router-view" :class="{ 'router-collapsed': collapsed || !username, 'sidebar-shown': !hideSidebar }">
          <navbar-top v-if="navbarTopShow" @logout="logout()" id="navbar"></navbar-top>
          <subbar v-if="!hideSidebar && !hideSubbar"></subbar>
          <RouterView :key="$route.fullPath" />
        </div>
      </transition>
      <Footer v-if="footerShow"></Footer>
    </div>
    <darkmode></darkmode>
    <div v-if="!hasConsent">
      <b-modal v-model="showCookieModal" :can-cancel="false" :width="640" scroll="keep">
        <cookie-consent-modal @preferences-saved="handleCookieConsent" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/common/event-bus"
import CookieLaw from "vue-cookie-law"
import CookieConsentModal from "./components/CookieConsentModal.vue"
import { isFeatureEnabled, trackEvent } from '@/common/analytics'

import Footer from "./components/Footer.vue"
import NavbarTop from "./components/NavbarTop.vue"
import Sidebar from "./components/Sidebar.vue"
import Subbar from "./components/Subbar.vue"
import Darkmode from "./components/Darkmode.vue"

export default {
  components: {
    "cookie-law": CookieLaw,
    Footer,
    NavbarTop,
    Sidebar,
    Subbar,
    Darkmode,
    CookieConsentModal
  },
  data() {
    return {
      collapsed: true,
      showCookieModal: false
    }
  },
  computed: {
    username() {
      return this.$store.state.user.username
    },
    darkMode() {
      return this.$store.state.app.darkMode
    },
    hideSidebar() {
      return this.$route.meta.hideSidebar
    },
    hideSubbar() {
      return this.$route.meta.hideSubbar || !this.username
    },
    isMobile() {
      return this.$store.state.app.isMobile
    },
    questionAnswerFeature() {
      return isFeatureEnabled("studybuddy")
    },
    footerShow() {
      return this.$route.meta.showFooter
    },
    navbarTopShow() {
      return this.$route.meta.navbarTopShow && !this.username
    },
    isStudyBuddyRoute() {
      return this.$route.path.includes("/chat")
    },
    hasConsent() {
      return this.$cookies.get('cookie_consent') !== null
    }
  },
  async mounted() {
    // Show cookie consent modal if no consent is stored
    if (!this.hasConsent) {
      this.showCookieModal = true
    }
    
    await this.init()

    this.setPremiumModalListener()

    EventBus.$on("collapseSidebarFromChevron", (collapsed) => {
      this.collapsed = collapsed
    })

    if (!this.isMobile) this.collapsed = false
  },
  methods: {
    async init() {
      // Only initialize app if we have cookie consent
      if (this.hasConsent) {
        await this.$store.dispatch("app/init")

        let refferal = this.$route.query.r || this.$route.query.utm_source
        if (typeof refferal !== "undefined") {
          // Only set cookies if we have functional consent
          const consent = this.$cookies.get('cookie_consent')
          if (consent && consent.functional) {
            this.$cookies.set("refferal", refferal)
          }
        }
      }
    },
    handleCookieConsent(preferences) {
      // If analytics is rejected, disable analytics
      if (!preferences.analytics) {
        // Disable Google Analytics
        if (window['ga-disable-' + process.env.VUE_APP_GA_ID]) {
          window['ga-disable-' + process.env.VUE_APP_GA_ID] = true
        }
        // Disable PostHog
        if (this.$posthog) {
          this.$posthog.opt_out_capturing()
        }
      }
      
      // Initialize app after consent
      this.init()
    },
    collapse() {
      EventBus.$emit("collapseSidebar", false)
    },
    setPremiumModalListener() {
      EventBus.$on("showPremium", (feature) => {
        const message = this.username ? "Deze optie is alleen beschikbaar voor premium gebruikers. Bekijk de premium pagina voor meer informatie." : "Deze optie is alleen beschikbaar voor premium gebruikers. Log in of maak een account aan om gebruik te maken van deze optie."

        return this.$buefy.dialog.confirm({
          title: "Premium optie",
          message: message,
          hasIcon: true,
          icon: "exclamation-thick",
          ariaRole: "alertdialog",
          ariaModal: true,
          cancelText: "Sluiten",
          confirmText: "Bekijken",
          onConfirm: () => {
            this.username ? this.redirectPremiumPage(feature.feature) : this.$router.push("/aanmelden")
          },
        })
      })
    },
    redirectPremiumPage(feature) {
      trackEvent("premium_redirect", { event_category: feature, value: 1 })
      this.$router.push("/premium")
    },
    redirectPlanning() {
      trackEvent("visit", { event_category: "planning", event_label: this.username, value: 1 })
      this.$router.push("/planning")
    },
  },
  watch: {
    darkMode(newDark) {
      if (newDark) {
        document.body.style.background = "#333333"
      } else {
        document.body.style.background = "#f0f0f0"
      }
    },
  },
}
</script>
<style lang="scss">
@import "~normalize.css/normalize.css";
@import "~nprogress/nprogress.css";
@import "~bulma/sass/utilities/_all";

#router-view.sidebar-shown {
  margin-left: 250px;
  transition: all 0.3s;
  &.router-collapsed {
    margin-left: 0;
  }
  @media only screen and (max-width: 1000px) {
    margin-left: 0;
  }
}

#debug {
  position: fixed;
  width: 500px;
  height: 300px;
  overflow-y: scroll;
  background: #fff;
  border: 1px solid #000;
  bottom: 10px;
  right: 10px;
}

$primary: #fc7234;
$primaryDark: #db5b20;

$link: #23b1de;
$green: #2dd634;
$yellow: #fcdc34;
$red: #d62d2d;
$blue: #23b1de;

$block-spacing: 2;

$colors: (
  "info": (
    $blue,
    $info-invert,
  ),
  "success": (
    $green,
    $success-invert,
  ),
  "warning": (
    $yellow,
    $warning-invert,
  ),
  "danger": (
    $red,
    $danger-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
);

@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "./assets/style/style.scss";
@import "./assets/style/transitions.scss";

#nprogress .bar {
  background: #fc7234;
  height: 3px;
}

#nprogress .peg {
  box-shadow: 0 0 10px #fc7234, 0 0 5px #fc7234;
}

#nprogress .spinner-icon {
  border-top-color: #fc7234;
  border-left-color: #fc7234;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100svh;
}

#navbar {
  transition: all 0.2s ease;
}

#navbar.collapse {
  opacity: 0;
  max-height: 0;
  min-height: 0;
  height: 0;
  padding: 0 !important;
  .container {
    height: 0 !important;
    display: none;
  }
}

.suggestion-btn {
  position: fixed;
  bottom: 10px;
  left: 10px;
  button {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
      box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.22);
    }
  }
}
</style>
