import Vue from 'vue'

export const canTrackAnalytics = () => {
  try {
    const cookies = Vue.prototype.$cookies
    if (!cookies) return false
    const consent = cookies.get('cookie_consent')
    return consent && consent.analytics
  } catch (e) {
    console.warn('Error checking analytics consent:', e)
    return false
  }
}

export const trackEvent = (eventName, params) => {
  if (!canTrackAnalytics()) return

  // Track in Google Analytics
  if (Vue.prototype.$gtag) {
    Vue.prototype.$gtag.event(eventName, params)
  }

  // Track in PostHog
  if (Vue.prototype.$posthog) {
    Vue.prototype.$posthog.capture(eventName, params)
  }
}

export const isFeatureEnabled = (featureName) => {
  try {
    if (!canTrackAnalytics() || !Vue.prototype.$posthog) {
      // If analytics are disabled or PostHog is not available, default to enabling the feature
      return true
    }
    return Vue.prototype.$posthog.isFeatureEnabled(featureName)
  } catch (e) {
    console.warn('Error checking feature flag:', e)
    return true
  }
}

export const identifyUser = (username, properties) => {
  if (!canTrackAnalytics() || !Vue.prototype.$posthog) return
  try {
    Vue.prototype.$posthog.identify(username, properties)
  } catch (e) {
    console.warn('Error identifying user:', e)
  }
} 